/* eslint-disable camelcase */
import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { ArrowIcon } from "../../images/trusted-partners/ArrowIcon";
import Markdown from "../Common/Markdown";
import { MediaRender } from "../Common";
import { RenderCardsPartnersProps } from "./resourcesCardPartners.types";
import phoneIcon from "../../images/trusted-partners/phone.png";

import {
  ArticleAdress,
  ArticleDescription,
  ArticlePhone,
  ArticlePhoneContainer,
  ArticleTitle,
  AwardsNavigationButton,
  Card,
  CardContainer,
  CardImage,
  CardTextContainer,
  Icon,
  IconArticle,
  ImageContainer,
  SliderContainer,
  TitleContainer,
} from "./resourcesCardPartners.styles";

const RenderCardsPartners = ({resource, isSubCategory, handleOpenModal }: RenderCardsPartnersProps) => {
  const [swiperInstance, setSwiperInstance] = useState();
  
  const {
    Article_Title,
    Article_Phone,
    Article_Description,
    Article_Images,
    Article_Adress,
    Article_Main_Image
  } = resource.attributes;

  const isOnlyMainImage = Article_Images.length <= 0;

  const SliderControls: React.FC<{side: string}> = (props) => {
    const handleNext = () => {
      swiperInstance.slideNext();
    }
    const handlePrev = () => {
      swiperInstance.slidePrev();
    }
  
    if (props.side === "right") return (
      <AwardsNavigationButton onClick={() => handleNext()} type={'next'}>
        <ArrowIcon />
      </AwardsNavigationButton>
      )
    if (props.side === "left") return (
      <AwardsNavigationButton onClick={() => handlePrev()} type={'prev'} >
        <ArrowIcon />
      </AwardsNavigationButton>
    )
  }  
  return (
  <Card 
      key={Article_Title} 
            isSubCategoryActive={isSubCategory}
    >
      <CardImage isSubCategoryActive={isSubCategory}>
        {!isOnlyMainImage ? 
          <>
            <SliderControls side="left" />
            <SliderControls side="right" />
            <SliderContainer>
              <Swiper
                loop
                centeredSlides={true}
                speed={700}
                slidesPerView={1}
                spaceBetween={0}
                onSwiper={(swiper) => setSwiperInstance(swiper)}
                className="mySwiper"
              >
                <SwiperSlide>
                  <MediaRender mediaSource={Article_Main_Image.data} />
                </SwiperSlide>
                {Article_Images && Article_Images.map((image) =>(
                  <SwiperSlide>
                      <MediaRender mediaSource={image.Image.data} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </SliderContainer>
          </> : 
          <ImageContainer>
            <MediaRender mediaSource={Article_Main_Image.data} />
          </ImageContainer>
        }
      </CardImage>
      <CardContainer onClick={()=> handleOpenModal(Article_Title)} isSubCategoryActive={isSubCategory}>
        <CardTextContainer isSubCategoryActive={isSubCategory}>
          <TitleContainer>
            <ArticleTitle isSubCategoryActive={isSubCategory}>
              {Article_Title}
            </ArticleTitle>
            <ArticleDescription>
              <Markdown text={Article_Description} textColor="grayText" />
            </ArticleDescription>
            {Article_Adress.map((adress: { Article_Adress: string }) => {
              return (
                <ArticleAdress>
                  <Markdown text={adress.Article_Adress} textColor="darkGray" />
                </ArticleAdress>
              );
            })}
            <ArticlePhoneContainer isSubCategoryActive={isSubCategory}>
              {Article_Phone.map((phone: { Article_Phone: string }) => {
                return (
                  <IconArticle isSubCategoryActive={isSubCategory}>
                      <Icon src={phoneIcon} alt="phone" />
                      <ArticlePhone>
                        <Markdown
                          text={phone.Article_Phone}
                          textColor="darkGray"
                        />
                      </ArticlePhone>
                  </IconArticle>
                );
              })}
            </ArticlePhoneContainer>
          </TitleContainer>
        </CardTextContainer>
      </CardContainer>
    </Card>
  );
};

export default RenderCardsPartners;
