import styled, { css } from "styled-components";
import { COLORS } from "../../styles/globals/colors";
import {
  DESKTOP_1200,
  DESKTOP_1280,
  TABLET_600,
  TABLET_768,
} from "../../styles/globals/sizes";

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.58);
  position: fixed;
  z-index: 4;
  top: 90px;
  left: 0;

  @media (max-width: ${DESKTOP_1200}px) {
    padding-bottom: 5%;
  }

 @media (max-width: ${TABLET_768}px) {
    top: 70px;
  }
`;

export const Wrapper = styled.div`
  width: 65%;
  max-width: 895px;
  height: calc(100% - 100px);
  border-radius: 10px;
  z-index: 5;
  overflow-y: auto;
  position: relative;
  bottom: 20px;

  @media (max-width: ${TABLET_768}px) {
    width: 95%;
  }
`;

export const CardImage = styled.div`
  width: 100%;
  position: relative;
  border-radius: 10px 10px 0 0;
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 14px;
  z-index: 6;
  svg {
    fill: ${COLORS.white};
  }

  svg{
    &:hover{
      fill: ${COLORS.redTitle};
    }
  }
`;

export const SliderContainer = styled.div`
  width: 100%;
  height: 100%;
  .swiper{

    border-radius: 10px 10px 0 0;
  }
  .swiper-slide {
  height: auto;
  }

  .swiper-pagination{
    bottom: 0;
  }
`;

export const DataContainer = styled.div`
  width: 100%;
  padding: 22px 30px 30px;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  border-radius: 0 0 10px 10px;

  @media (max-width: ${DESKTOP_1280}px) {
    padding: 16px 16px 26px;
  }
`;

export const ArticleTitle = styled.h2`
  width: 100%;
  font: normal normal 500 34px/40px 'Roboto';
  letter-spacing: 0px;
  color: #333333;

  @media (max-width: ${TABLET_768}px) {
    display: none;
  }
`;

export const TitleMobileContainer = styled.div`
  padding: 0 18px;
  display: none;
  position: absolute;
  bottom: 21px;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.84);
  font: normal normal 500 24px/23px 'Roboto';
  color: ${COLORS.white};
  z-index: 5;

  @media (max-width: ${TABLET_768}px) {
    display: block;
  }
`;

export const Button = styled.a`
  width: 275px;
  height: 47px;
  display: flex;
  background: #e0281a;
  border-radius: 24px;
  text-align: center;
  font: normal normal 500 18px/24px 'Roboto';
  color: #ffffff;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  cursor: pointer;

  &:hover {
    background: #ec4746;
  }

  @media (max-width: ${TABLET_768}px) {
    flex-direction: column;
    align-self: center;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 28px;
  
  @media (max-width: ${DESKTOP_1280}px) {
    margin-top: 10px;
    flex-direction: column;
    gap: 8px;
  }
`;

export const ArticleAdress = styled.p`
  width: 100%;
  font: normal normal normal 18px/23px 'Roboto';
  letter-spacing: 0px;
  color: #333333;

  @media (max-width: ${TABLET_768}px) {
    font: normal normal normal 14px/18px 'Roboto';
  }
`;

export const Icon = styled.img`
  width: 23px;
  height: 23px;
  margin-right: 10px;

  @media (max-width: ${TABLET_600}px) {
    width: 20px;
    height: 20px;
  }
`;

export const ArticlePhone = styled.p`
  font: normal normal normal 18px/23px 'Roboto';
  letter-spacing: 0px;
  color: #333333;
  margin-right: 45px;

  @media (max-width: ${TABLET_768}px) {
    font: normal normal normal 16px/23px 'Roboto';
  }
`;

export const ArticleDescription = styled.p`
  width: 53%;
  font: normal normal normal 14px/20px 'Roboto';
  letter-spacing: 0px;
  color: #404040;

  @media (max-width: ${DESKTOP_1280}px) {
    width: 100%;
  }

  @media (max-width: ${TABLET_768}px) {
    font: normal normal normal 14px/18px 'Roboto';
  }
`;

export const ArticlePhoneContainer = styled.a`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  place-items: flex-end;
`;

export const IconArticle = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContactInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ArticleAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const SubtitleContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;


  @media (max-width: ${DESKTOP_1280}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

export const AssistedContainer = styled.div`
  margin-right: 23px;
  padding: 6px 15px;
  border-radius: 23px;
  border: 1px solid ${COLORS.grayTopbarItem};
  font: normal normal normal 16px/20px 'Roboto';
  text-align: center;
`;

export const CountyTypeOfCareContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BoldText = styled.ul`
  display: inline;
  font: normal normal bold 16px/20px 'Roboto';
  color: #404040;
`;

export const Text = styled.li`
  margin-left: 5px;
  display: contents;
  font: normal normal normal 16px/20px 'Roboto';
  color: #404040;
`;

export const Separator = styled.li`
  margin: 0 5px;
  display: contents;
  font: normal normal normal 16px/20px 'Roboto';
  color: #404040;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const AwardsNavigationButton = styled.div<{type: string}>`
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 30px;
  position: absolute;
  top: 50%;
  z-index: 2;
  border-radius: 50%;
  background-color: #ED4746;
  ${({type}) => (type ==='next') ? css`right: 10px` : css`left: 10px` };
  svg{
    width: 9px;
    height: 16px;
    transform: ${({type}) => (type ==='prev') ? 'scale(-1)' : ''};
    fill: ${COLORS.white}
  }
  &:hover{
    background-color: ${COLORS.white};
    svg {
      fill: #ED4746; 
    }
  }
`;
