import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { ArrowIcon } from "../../images/trusted-partners/ArrowIcon";
import { CloseIcon } from "../../images/trusted-partners/CloseIcon";
import { IRenderCardsPartnersModal } from "./resourcesCardPartnersModal.types";
import Markdown from "../Common/Markdown";
import { MediaRender } from "../Common";
import phoneIcon from "../../images/trusted-partners/phone.png";

import {
  ArticleAddressContainer,
  ArticleAdress,
  ArticleDescription,
  ArticlePhone,
  ArticlePhoneContainer,
  ArticleTitle,
  AssistedContainer,
  AwardsNavigationButton,
  BoldText,
  Button,
  ButtonContainer,
  CardImage,
  CloseIconContainer,
  ContactInformationContainer,
  CountyTypeOfCareContainer,
  DataContainer,
  DescriptionContainer,
  Icon,
  IconArticle,
  ImageContainer,
  ModalContainer,
  Separator,
  SliderContainer,
  SubtitleContainer,
  Text,
  TitleMobileContainer,
  Wrapper,
} from "./resourcesCardPartnersModal.styles";

const RenderCardsPartnersModal = ({
  handleClose,
  data,
}: IRenderCardsPartnersModal) => {
  const [swiperInstance, setSwiperInstance] = useState();

  const {
    Article_Title,
    Article_Phone,
    Article_Description,
    Article_Images,
    Article_Adress,
    Article_CTA_Link,
    Article_County,
    Article_Types_Of_Care,
    Article_Main_Image,
    Article_Category,
  } = data.attributes;

  const isOnlyMainImage = Article_Images.length <= 0;

  const SliderControls: React.FC<{ side: string }> = (props) => {
    const handleNext = () => {
      swiperInstance.slideNext();
    };
    const handlePrev = () => {
      swiperInstance.slidePrev();
    };

    if (props.side === "right")
      return (
        <AwardsNavigationButton onClick={() => handleNext()} type="next">
          <ArrowIcon />
        </AwardsNavigationButton>
      );
    if (props.side === "left")
      return (
        <AwardsNavigationButton onClick={() => handlePrev()} type="prev">
          <ArrowIcon />
        </AwardsNavigationButton>
      );
  };

  const getSubCategory = () => {
    const categories = Article_Category.data.map(
      (category: { attributes: { Category_Title: string } }) => {
        const firstSpaceIndex = category.attributes.Category_Title.indexOf(" ");
        return category.attributes.Category_Title.substring(
          firstSpaceIndex + 1
        );
      }
    );

    if (categories.length > 0 && categories.includes("Facilities")) {
      categories.shift();
      return categories;
    }
    return [];
  };

  const subCategories = getSubCategory();

  return (
    <ModalContainer onClick={handleClose}>
      <Wrapper onClick={(e) => e.stopPropagation()}>
        <CloseIconContainer onClick={handleClose}>
          <CloseIcon />
        </CloseIconContainer>
        <CardImage>
          {!isOnlyMainImage ? (
            <>
              <SliderControls side="left" />
              <SliderControls side="right" />
              <SliderContainer>
                <Swiper
                  loop
                  centeredSlides
                  speed={700}
                  slidesPerView={1}
                  spaceBetween={0}
                  onSwiper={(swiper) => setSwiperInstance(swiper)}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <MediaRender mediaSource={Article_Main_Image.data} />
                  </SwiperSlide>
                  {Article_Images &&
                    Article_Images.map((image) => (
                      <SwiperSlide>
                        <MediaRender mediaSource={image.Image.data} />
                      </SwiperSlide>
                    ))}
                </Swiper>
              </SliderContainer>
            </>
          ) : (
            <ImageContainer>
              <MediaRender mediaSource={Article_Main_Image.data} />
            </ImageContainer>
          )}
          <TitleMobileContainer>{Article_Title}</TitleMobileContainer>
        </CardImage>

        <DataContainer>
          <ArticleTitle>{Article_Title}</ArticleTitle>
          {(Article_County ||
            Article_Types_Of_Care ||
            subCategories.length > 0) && (
            <SubtitleContainer>
              {subCategories.map((subcategories: string) => (
                <AssistedContainer>{subcategories}</AssistedContainer>
              ))}
              <CountyTypeOfCareContainer>
                <BoldText>
                  {Article_County && (
                    <>
                      County: <Text>{Article_County}</Text>
                    </>
                  )}
                  {Article_County && Article_Types_Of_Care && (
                    <Separator> | </Separator>
                  )}
                  {Article_Types_Of_Care && (
                    <>
                      Types of Care: <Text>{Article_Types_Of_Care}</Text>
                    </>
                  )}
                </BoldText>
              </CountyTypeOfCareContainer>
            </SubtitleContainer>
          )}
          <DescriptionContainer>
            <ArticleDescription>
              <Markdown text={Article_Description} textColor="grayText" />
            </ArticleDescription>
            <ContactInformationContainer>
              <ArticleAddressContainer>
                {Article_Adress.map((adress: { Article_Adress: string }) => {
                  return (
                    <ArticleAdress>
                      <Markdown
                        text={adress.Article_Adress}
                        textColor="darkGray"
                      />
                    </ArticleAdress>
                  );
                })}
              </ArticleAddressContainer>
              <ArticlePhoneContainer>
                {Article_Phone.map((phone: { Article_Phone: string }) => {
                  return (
                    <IconArticle>
                      <Icon src={phoneIcon} alt="phone" />
                      <ArticlePhone>
                        <Markdown
                          text={phone.Article_Phone}
                          textColor="darkGray"
                        />
                      </ArticlePhone>
                    </IconArticle>
                  );
                })}
              </ArticlePhoneContainer>
            </ContactInformationContainer>
          </DescriptionContainer>
          <ButtonContainer>
            <Button href={Article_CTA_Link} target="_blank">
              VISIT WEB
            </Button>
          </ButtonContainer>
        </DataContainer>
      </Wrapper>
    </ModalContainer>
  );
};

export default RenderCardsPartnersModal;
