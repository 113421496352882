import styled, { css } from 'styled-components'
import { COLORS } from "../../styles/globals/colors";
import {
  DESKTOP_1280,
  MOBILE_460,
  TABLET_600,
  TABLET_768,
  TABLET_992,
} from "../../styles/globals/sizes";
import { cardProps } from "./resourcesCardPartners.types";

export const Card = styled.a<cardProps>`
  width: 100%;
  background: ${COLORS.white};
  border-radius: 16px;
  margin-bottom: 45px;
  display: flex;
  flex-direction: row;
  position: relative;
  text-style: none;
  text-decoration: none;
  overflow: hidden;
  transition: transform 0.1s;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  cursor: default;

  @media (max-width: ${DESKTOP_1280}px) {
    ${(props) => props.isSubCategoryActive && "flex-direction: column;"}
  }

  @media (max-width: ${TABLET_992}px) {
    flex-direction: column;
  }

  @media (max-width: ${MOBILE_460}px) {
    width: 95%;
  }
`;

export const ArticlePhoneContainer = styled.a<cardProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  place-items: flex-end;
  margin-top: 25px;

  @media (max-width: ${DESKTOP_1280}px) {
    ${(props) =>
      props.isSubCategoryActive &&
      "align-items: start; flex-direction: column;"};
  }
`;

export const IconArticle = styled.div<cardProps>`
  display: flex;
  flex-direction: row;

  @media (max-width: ${DESKTOP_1280}px) {
    ${(props) => props.isSubCategoryActive && "margin-bottom: 15px"};
  }
`;

export const ArticleProduct = styled.img`
  display: block;
  max-height: 25px;
  margin: 10px 10px 15px 0;
  max-width: 146px;
  width: fit-content !important;
  height: 100%;
`;

export const ArticleTitle = styled.h2<cardProps>`
  width: 100%;
  font: normal normal 500 34px/40px Roboto;
  letter-spacing: 0px;
  color: #333333;

  @media (max-width: ${TABLET_600}px) {
    font: normal normal 500 24px/23px Roboto;
  }
`;

export const ArticleDescription = styled.p`
  width: 100%;
  font: normal normal normal 14px/20px Roboto;
  letter-spacing: 0px;
  color: #404040;
  margin-top: 25px;
  margin-bottom: 5px;

  @media (max-width: ${TABLET_600}px) {
    font: normal normal normal 14px/18px Roboto;
  }
`;

export const ArticleAdress = styled.p`
  width: 100%;
  font: normal normal normal 18px/23px Roboto;
  letter-spacing: 0px;
  color: #333333;
  margin-top: 15px;

  @media (max-width: ${TABLET_600}px) {
    font: normal normal normal 16px/24px Roboto;
  }
`;

export const Icon = styled.img`
  width: 23px;
  height: 23px;
  margin-right: 10px;

  @media (max-width: ${TABLET_600}px) {
    width: 20px;
    height: 20px;
  }
`;

export const ArticlePhone = styled.p`
  font: normal normal normal 18px/23px Roboto;
  letter-spacing: 0px;
  color: #333333;
  margin-right: 45px;

  @media (max-width: ${TABLET_600}px) {
    font: normal normal normal 16px/23px Roboto;
    margin-right: 15px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
`;

export const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

export const Line = styled.div`
  width: 100%;
  margin-top: 12px;
  margin-bottom: 5px;
  border: none;
  border-top: 1px solid #bc72ef;
`;

export const CardTextContainer = styled.div<cardProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 50px;
  overflow: hidden;

  @media (max-width: ${DESKTOP_1280}px) {
    ${(props) => props.isSubCategoryActive && "padding: 15px 20px;"}
  }
  @media (max-width: ${TABLET_992}px) {
    padding: 15px 20px
  }
`;

export const CardContainer = styled.div<{ floating?: boolean, isSubCategoryActive: boolean }>`
  width: ${({isSubCategoryActive}) => isSubCategoryActive ? "60%" : "56%"};
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f2f2f2 0% 0% no-repeat padding-box;
  cursor: pointer;
  ${(props) =>
    props.floating &&
    `
  height: 350px;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-radius: 0;
  border: none;
  justify-content: flex-end;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0) 24%, rgba(255,255,255,0.8421962535014006) 60%, rgba(255,255,255,1) 80%);
  `}


@media (max-width: ${DESKTOP_1280}px) {
    width: 100%;
  }
`;

export const CardImage = styled.div<cardProps>`
  width: ${(props) => props.isSubCategoryActive ? "40%" : "44%"};
  height: 100%;
  position: relative;

  @media (max-width: ${DESKTOP_1280}px) {
    ${(props) => props.isSubCategoryActive && "width: 100%;"}
  }

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
  }

  @media (max-width: ${TABLET_768}px) {
    height: 500px;
    img {
      object-fit: cover;
      object-position: center top;
    }
  }

  @media (max-width: ${TABLET_600}px) {
    height: 400px;
  }

  @media (max-width: ${MOBILE_460}px) {
    height: 300px;
  }
`;

export const Arrow = styled.img`
  width: 15px !important;
  height: 15px !important;
  margin-top: 3px;
  margin-left: 4px;
`;

export const Tag = styled.div`
  height: 22px;
  display: flex;
  color: ${COLORS.white};
  font: normal normal 500 11px/13px Roboto;
  background: #e0281a 0% 0% no-repeat padding-box;
  border-radius: 11px;
  align-self: flex-start;
  padding: 5px 10px 10px 10px;
  margin-bottom: 15px;
`;

export const SliderContainer = styled.div`
     width: 100%;
     height: 100%;

     .swiper-slide {
        width: 100%!important;
        height: auto;
      }

      .swiper-pagination{
        bottom: 0;
      }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const AwardsNavigationButton = styled.div<{type: string}>`
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 30px;
  position: absolute;
  top: 50%;
  z-index: 2;
  border-radius: 50%;
  background-color: #ED4746;
  ${({type}) => (type ==='next') ? css`right: 10px` : css`left: 10px` };
  svg{
    width: 9px;
    height: 16px;
    transform: ${({type}) => (type ==='prev') ? 'scale(-1)' : ''};
    fill: ${COLORS.white}
  }
  &:hover{
    background-color: ${COLORS.white};
    svg {
      fill: #ED4746; 
    }
  }
`;
