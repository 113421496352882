import React, { SVGProps } from 'react'

export const CloseIcon = (props?: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="28.215" height="29.535" viewBox="0 0 28.215 29.535" {...props}>
    <defs>
        <filter id="circle-x" x="0" y="0" width="28.215" height="29.535" filterUnits="userSpaceOnUse">
        <feOffset dy="3"/>
        <feGaussianBlur stdDeviation="2.5" result="blur"/>
        <feFlood flood-opacity="0.667"/>
        <feComposite operator="in" in2="blur"/>
        <feComposite in="SourceGraphic"/>
        </filter>
    </defs>
    <g id="circle-x-2" data-name="circle-x" transform="translate(7.5 4.5)">
        <g transform="matrix(1, 0, 0, 1, -7.5, -4.5)" filter="url(#circle-x)">
        <path id="circle-x-3" data-name="circle-x" d="M19.941,8.349l-4.568,5.189,4.568,5.189a1.226,1.226,0,0,1-.159,1.781,1.367,1.367,0,0,1-1.863-.152L13.645,15.5,9.37,20.356a1.367,1.367,0,0,1-1.863.152,1.225,1.225,0,0,1-.159-1.781l4.568-5.189L7.349,8.349a1.226,1.226,0,0,1,.159-1.781,1.362,1.362,0,0,1,1.862.152l4.274,4.856L17.918,6.72a1.363,1.363,0,0,1,1.862-.152,1.225,1.225,0,0,1,.159,1.781Z" transform="translate(0.46 -1.77)" />
        </g>
    </g>
    </svg>
)