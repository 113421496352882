import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { CONTACT } from "../config/urls";
import Seoinfo from "../components/seoinfo/seoinfo";
import seoImage from "../../static/seo/seo_image.png";

import Layout from "../components/layout";
import GetInTouchSection from "../components/GetInTouchSection";

import PartnersIndex from "../components/Partners/PartnersIndex";
import Hero from "../images/partners/hero.png";
import HeroTablet from "../images/partners/img-homecare02.png";
import HeroMobile from "../images/partners/mobile-hero.png";

import {
  HeroContainer,
  InternalWrapper,
  MobileDiv,
  HeroTextDiv,
  Title,
  Paragraph,
  PhoneAndParagraph,
  HeroPicture,
  TabletDiv,
} from "../styles/pages/partners.jsx";

const Partners = () => {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <HelmetProvider key={isClient ? "client" : "server"}>
      <Layout hasTransparentTopBar>
        <Helmet>
          <link rel="canonical" href="https://homecare.life/partners" />
        </Helmet>
        <Seoinfo
          siteName="Trusted Partners - Life HomeCare"
          titlePage="Trusted Partners - Life HomeCare"
          description="Transform Your Homecare Experience with ES Trusted Partners! Quality Care, Compassionate Support. The ideal companions for you are waiting."
          keywords="Home, Care, HomeCare, Senior, Compassionate, Care, Home Care Agency, Home Care Agencies"
          type="website"
          url="/partners"
          imageFacebook={seoImage}
        />
        <HeroContainer bgImage={Hero} alt="Smiling Life Homecare Nurse">
          <HeroPicture>
            <source srcSet={Hero} type="image/avif" />
            <img src={Hero} alt="Smiling Life Homecare Nurse" />
          </HeroPicture>
          <InternalWrapper data-aos="fade">
            <TabletDiv bgImage={HeroTablet} />
            <MobileDiv bgImage={HeroMobile} />
            <HeroTextDiv>
              <Title>TRUSTED PARTNERS</Title>
              <PhoneAndParagraph>
                <Paragraph>
                  Transform Your Homecare Experience with our Trusted Partners!
                  Quality Care, Compassionate Support. The ideal companions for
                  you are waiting.
                </Paragraph>
              </PhoneAndParagraph>
            </HeroTextDiv>
          </InternalWrapper>
        </HeroContainer>
        <PartnersIndex />
        <GetInTouchSection contactEmail={CONTACT.MAIL} />
      </Layout>
    </HelmetProvider>
  );
};

export default Partners;
